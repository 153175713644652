<template>
  <b-modal
    id="registered-agent-details-modal"
    ref="registered-agent-details-modal"
    size="lg"
    :hide-header-close="true"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
  >
    <template v-slot:modal-title>
      <h3 class="m-0 p-0">
        Your Registered Agent Details
      </h3>
    </template>
    <template v-slot:default>
      <h4 class="pl-4 pr-4">
        {{state}}
      </h4>
    </template>
    <template v-slot:modal-footer>
      <div class="footer-container">
        <div class="footer-section-top border-bottom">
          <div class="footer-details">
            <div id="copyDetails" class="copy-details">
              <p>{{name}}</p>
              <p>{{line1}}</p>
              <p>{{line2}}</p>
              <p>{{cityStatePostalCode}}</p>
              <p>{{county}}</p>
              <p>{{country}}</p>
            </div>
            <div class="tooltip-container">
              <copy-icon
                class="ml-2 mt-2 copy-icon"
                @click="copyAddress"
              />
              <span class="tooltip-text">Copy Address</span>
            </div>
          </div>
          <div class="date-container">
            <div class="date-row">
              <p class="date-label">Start Date:</p>
              <p class="date-value">{{ startDate }}</p>
            </div>
            <div class="date-row">
              <p class="date-label">End Date:</p>
              <p class="date-value">{{ endDate }}</p>
            </div>
          </div>
        </div>
        <span v-if="additionalDetails" class="footer-section-mid" v-html="additionalDetails" />
        <div class="footer-section-bottom">
          <secondary-button
            class="secondary-btn"
            button-text="Find a Pre-filled Form"
            @onclick="navigateToFormsLibrary('forms-library')"
          />
          <secondary-button
            class="secondary-btn"
            button-text="Help Me With My Filing"
            @onclick="navigateToSelectDomesticRegistration('stageline')"
          />
          <primary-button
            class="primary-btn"
            button-text="Close"
            aria-label="close registered agent details modal"
            @onclick="close"
          />
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { copyClipboardMixin } from '@/mixins/copyClipboardMixin'
import { makeToastMixin } from '@/mixins/makeToastMixin'
import {
  completeInteraction,
} from '@/common/modules/clientInteractionLog'
import * as Cookies from 'js-cookie'

export default {
  name: 'RegisteredAgentDetailsModal',
  components: {
    CopyIcon: () => import('@images/ui/copy-icon.svg'),
    PrimaryButton: () => import('@/components/StagelineV2/shared/PrimaryButton'),
    SecondaryButton: () => import('@/components/StagelineV2/shared/SecondaryButton'),
  },
  mixins: [copyClipboardMixin, makeToastMixin],
  props: {
    interaction: {
      type: Object,
      default: null,
    },
    raService: {
      type: Object,
      default: null,
    },
  },
  computed: {
    isNotAdminUser() {
      return sessionStorage.getItem('admin-logged-in') !== 'true' &&
        Cookies.get('admin') !== 'logged_in_as_client'
    },
    additionalDetails() {
      return this.raService.registered_agent_additional_details
    },
    city() {
      return this.raService.registered_agent_address.city
    },
    county() {
      return this.raService.registered_agent_address.county
    },
    country() {
      return this.raService.registered_agent_address.country
    },
    line1() {
      return this.raService.registered_agent_address.line1
    },
    line2() {
      return this.raService.registered_agent_address.line2
    },
    name() {
      return this.raService.registered_agent_name
    },
    postalCode() {
      return this.raService.registered_agent_address.zip_postal_code
    },
    state() {
      return this.raService.product.registered_agent?.jurisdiction?.state_province_region
    },
    stateAbbreviation() {
      return this.raService.registered_agent_address.state_province_region
    },
    cityStatePostalCode() {
      return `${this.city}, ${this.stateAbbreviation} ${this.postalCode}`
    },
    startDate() {
      return this.convertUnixToDate(this.raService.start);
    },
    endDate() {
      return this.convertUnixToDate(this.raService.stop);
    },
  },
  methods: {
    close() {
      this.logCloseModalInteraction()
      this.$bvModal.hide('registered-agent-details-modal')
    },
    convertUnixToDate(unixTime) {
      const date = new Date(unixTime * 1000);
      // format leading zeros if needed
      const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
      const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
      return `${month}/${day}/${date.getFullYear()}`;
    },
    copyAddress() {
      let textToCopy = `${this.line1}, ${this.line2}, ${this.city}, ${this.stateAbbreviation} ${this.postalCode}, ${this.country}`
      this.copyToClipboard(textToCopy, document.getElementById('copyDetails'), this.successToast, this.errorToast)
    },
    logButtonNavigationInteraction(destinationName) {
      this.completeInteraction({
        action: 'redirect',
        name: destinationName,
      })
    },
    logCloseModalInteraction() {
      this.completeInteraction({
        action: 'close',
        name: 'modal',
      })
    },
    completeInteraction(action) {
      if (this.isNotAdminUser) {
        completeInteraction({
          id: this.interaction.id,
          object_id: this.raService.id,
          object_table: 'Services',
          interaction: action,
          completed: true,
        })
      }
    },
    async navigateToSelectDomesticRegistration(destinationName) {
      this.logButtonNavigationInteraction(destinationName)
      await this.$router.push({ name: 'stageline-v2-decision-tree', params: { companyId: this.raService.company_id, redirect: 'true' } })
    },
    async navigateToFormsLibrary(destinationName) {
      this.logButtonNavigationInteraction(destinationName)
      await this.$router.push({ name: 'jurisdictionFilings', params: { companyId: this.raService.company_id, jurisdictionId: this.raService.product.registered_agent.jurisdiction_id }})
    },
  },
}
</script>
<style scoped lang="scss">
#registered-agent-details-modal {

  .right-align {
    text-align: right !important;
    font-weight: 900;
  }

  .footer-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .footer-section-top {
      width: 100%;
      padding-bottom: 1.5rem;
      display: flex;
      justify-content: space-between;

      .footer-details {
        padding-left: 1.0rem;
        display: flex;
        flex-direction: row;

        .copy-details {
          p {
            margin-bottom: 0;
          }
        }

        .tooltip-container {
          position: relative;
          display: inline-block;

          .copy-icon:hover {
            cursor: pointer;
          }

          .tooltip-text {
            visibility: hidden;
            background-color: #333;
            color: white;
            text-align: center;
            padding: 0.25rem 0.5rem;
            border-radius: 5px;
            font-size: 0.85rem;

            position: absolute;
            bottom: 100%;
            left: 200%;
            transform: translateX(-50%);
            white-space: nowrap;
            opacity: 0;
            transition: opacity 0.2s ease-in-out;
          }

          &:hover .tooltip-text {
            visibility: visible;
            opacity: 1;
          }
        }
      }

      .date-container {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        max-height: 3rem;

        .date-row {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .date-label,
          .date-value {
            margin-bottom: 0;
          }

          .date-label {
            min-width: 6.25rem;
            padding-right: 0.25rem;
            font-weight: 900;
            text-align: right;
          }

          .date-value {
            text-align: left;
          }
        }
      }
    }

    .footer-section-mid {
      width: 100%;
      padding: 1.5rem 1.0rem 0;
    }

    .footer-section-bottom {
      width: 100%;
      margin-top: 1.0rem;
      margin-bottom: 0.5rem;
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-end;

      .secondary-btn {
        margin-right: 0.5rem;
      }
    }
  }
}




@media only screen and (max-width: 576px) {
  #registered-agent-details-modal {
    width: 98%;

    h4 {
      margin: 1.5rem 0;
    }

    .footer-container {

      .footer-section-top {
        flex-flow: column nowrap;
        justify-content: center;
        align-items: flex-start;
        gap: 1.0rem;

        .footer-details {

          .copy-details p {
            display: block;
            text-indent: -0.5rem;
            word-wrap: break-word;
            overflow-wrap: break-word;
          }

          .tooltip-container {

            .tooltip-text {
              left: -50%;
            }
          }
        }

        .date-container {
          margin-left: 1.0rem;

          .date-row {

            .date-label {
              text-align: left;
            }
          }
        }
      }

      .footer-section-mid {
        margin-bottom: 0.5rem;
      }

      .footer-section-bottom {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1.0rem;

        .secondary-btn {
          margin-right: 0;
        }

        .secondary-btn,
        .primary-btn {
          width: 100%;
        }
      }
    }
  }
}
</style>
